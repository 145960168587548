/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.10.0-v202502052238-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface WorkstepChronologyOutputV1 {
    /**
     * The direction of the chronology relative to the given starting workstep (before or after).
     */
    "direction"?: DirectionEnum;
    /**
     * The list of workstep chronology entries.
     */
    "entries"?: Array<models.WorkstepChronologyEntryV1>;
    /**
     * The workstep ID from which the chronology starts.
     */
    "fromWorkstepId"?: string;
}

export enum DirectionEnum {
    Before = 'Before' as any,
    After = 'After' as any
}
